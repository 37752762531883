import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { PageNav } from '../components/PageTitle';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { TextInputForm, DropDownUI, Calender } from '../components/Forms';
import moment from "moment/moment";
import axiosRequest from '../service/api';
import { FaUserEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const product = [
    { label: "PEPSI", value: "pepsi" },
    { label: "BRITANNIA", value: "britannia" },
    { label: "COLGATE", value: "colgate" },
    { label: "LAYS", value: "lays" },
];

const MisManagerExcel = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        date: '',
        product: '',
    });
    const [loading, setLoading] = useState(false);
    const [reportsDataArray, setReportsDataArray] = useState([]);
    console.log("reportsDataArray", reportsDataArray);
    console.log("formData", formData);

    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    };

    const setLabel = (value, field) => {
        setFormData({
            ...formData,
            [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
        });
    };

    const reportsData = async () => {
        setLoading(true);
        const { date, product } = formData;
        const headers = {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        };

        try {
            setReportsDataArray([]);
            const response = await axiosRequest(
                'get',
                'manager/dateuploadreterive',
                null,
                { date, productname: product },
                { headers }
            );
            setReportsDataArray(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    const downloadExcel = () => {
        // Map your data to match the table columns
        const dataForExcel = reportsDataArray.map((row) => ({
            "InvoiceNumber":row.invoice_number,
            "InvoiceDate":row.invoice_date,
            "CustomerCode":row.customer_code,
            "CustomerName":row.customer_name,   
            "DeliveryMan": row.delivery_man,
            "DeliveryManId":row.delivery_man_login_id,
            "BeatCode":row.beat_code,
            "BeatCodeName":row.beat_code_name,
            // "deliverydate":date,
            
            "Amount": row.amount
        }));

        // Create a worksheet from the JSON data
        const worksheet = XLSX.utils.json_to_sheet(dataForExcel);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Reports Data");

        // Write the workbook to a file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Save the file
        const fileName = `reports_data_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
    };

    const columns = [
        { field: 'delivery_man', headerName: 'Delivery Man', width: 200 },
        { field: 'delivery_man_login_id', headerName: 'Delivery Man Id', width: 200 },
        { field: 'customer_name', headerName: 'Customer Name', width: 200 },
        { field: 'customer_code', headerName: 'Customer Code', width: 200 },
        { field: 'product_name', headerName: 'Product Name', width: 200 },
        { field: 'beat_code', headerName: 'Beat Code', width: 200 },
        { field: 'beat_code_name', headerName: 'Beat CodeName', width: 200 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'amount', headerName: 'Amount', width: 200 },
    ];

    console.log("reports array", reportsDataArray);
    useEffect(() => {
        if (!localStorage.getItem("userid")) {
            navigate("/login")
        }
    }, [])
    return (
        <div>
            <div>
                <Container fluid>
                    <div className='main my-3'>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <div className="page-nav py-3 h5">
                                    Manager Excel Data
                                </div>
                            </Col>
                            <Col lg='3' md='6' xs='12' className='py-3'>
                                <div className="w-100">
                                    <Calender
                                        setLabel={(date) => setLabel(date, "date")}
                                        selectedDate={formData.date}
                                        calenderlabel="Date"
                                    />
                                </div>
                            </Col>
                            <Col lg='3' md='6' xs='12' className='py-3'>
                                <div className='w-100'>
                                    <DropDownUI
                                        optionlist={product}
                                        placeholder="Product"
                                        labelname="Product"
                                        name="product"
                                        value={formData.product}
                                        onChange={(updatedFormData) =>
                                            setFormData({
                                                ...formData,
                                                product: updatedFormData,
                                            })
                                        }
                                    ></DropDownUI>
                                </div>
                            </Col>
                            <Col lg="12" md="6" xs="12" className="align-self-center py-3">
                                <div className="d-flex mt-4 justify-content-end">
                                    <div className="px-2">
                                        <ClickButton
                                            onClick={reportsData}
                                            label={<>SUBMIT</>}
                                            className="create-btn "
                                        ></ClickButton>
                                    </div>
                                    <div className="px-2">
                                        <ClickButton
                                            onClick={downloadExcel}
                                            label={<>Download Excel</>}
                                            className="create-btn "
                                        ></ClickButton>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ height: 500, width: '100%' }}>
                                    <DataGrid
                                        rows={reportsDataArray}
                                        columns={columns}
                                        loading={loading}
                                        autoPageSize
                                        getRowId={(row) => Math.random()}
                                        disableSelectionOnClick
                                        sx={{
                                            '& .MuiDataGrid-columnHeader': {
                                                backgroundColor: '#4B0082',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    '.MuiSvgIcon-root': {
                                                        color: 'white',
                                                    },
                                                },
                                            },
                                            '& .MuiDataGrid-columnHeader--alignLeft': {
                                                textAlign: 'left',
                                            },
                                            '& .MuiDataGrid-columnHeader--sortable': {
                                                cursor: 'pointer',
                                            },
                                            '& .MuiDataGrid-withBorderColor': {
                                                borderColor: '#530004',
                                            },
                                            '& .MuiDataGrid-scrollbar--vertical': {
                                                display: 'none',
                                            },
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default MisManagerExcel;
