import React, { useState, forwardRef ,useEffect} from "react";
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Forms.css'

const InstantModel = ({ optionlist = [], className, name, labelname, placeholder, value, onChange, modeltitle = "create" }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    if (selectedValue !== value) {
      onChange({
        ...value,
        [name]: selectedValue
      });
    }
  };

  // Find the selected option based on the current value
  const selectedOption = optionlist.find(option => option.value === value);

  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>

      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          labelField='label'
          valueField='value'
          value={selectedOption} // Set the selected option
          onChange={handleChange}
          multi
          className='w-100'
        />


      </div>
    </>
  );
};

const TextForm = forwardRef(({ name, type, suffix_icon, prefix_icon, labelname, value, onChange, placeholder, onKeyDown, autoFocus, disabled, onKeyPress }, ref) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='form-icon'>
        <Form.Group className="">
          {prefix_icon ? <span className='prefix-icon'>{prefix_icon}</span> : ""}
          <input
            type={type}
            name={name}
            className={`form-cntrls w-100 
                      ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : ""}`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyPress={onKeyPress}
            ref={ref}
          />
          {suffix_icon ? <span className='suffix-icon'>{suffix_icon}</span> : ""}
        </Form.Group>
      </div>
    </>
  )
});
const TextInputForm = ({ name, type, suffix_icon, prefix_icon, labelname, value, onChange, placeholder, onKeyDown, autoFocus, disabled, onKeyPress }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='form-icon'>
        <Form.Group className="">
          {prefix_icon ? <span className='prefix-icon'>{prefix_icon}</span> : ""}
          <input
            type={type}
            name={name}
            className={`form-cntrl w-100 
                      ${prefix_icon && suffix_icon ? "form-control-padboth" : prefix_icon ? "form-control-padleft" : suffix_icon ? "form-control-padright" : ""}`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            disabled={disabled}
            onKeyPress={onKeyPress}
          />
          {suffix_icon ? <span className='suffix-icon'>{suffix_icon}</span> : ""}
        </Form.Group>
      </div>
    </>
  )
}
const DropDown = ({ optionlist, labelname, placeholder, modeltitle = "Create" }) => {
  return (
    <>
      <div className='pb-2'>
        {labelname ? <label>{labelname}</label> : ""}
      </div>
      <div className='w-100 d-flex'>
        <Select
          options={optionlist}
          placeholder={placeholder}
          labelField='label'
          valueField='value'
          isMulti className='w-100 '
        >
        </Select>
      </div>
    </>
  )
}
//clear button click to dropdown value clear 
const DropDownUI = ({ optionlist = [], name, labelname, placeholder, value, onChange, ...rest }) => {
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    onChange(selectedValue);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: '#4B0082',
      outline: 'none',
      backgroundColor: '#EFEFEF',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
      '&:hover': { borderColor: '#4B0082' }
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4B0082' : provided.backgroundColor,
      '&:hover': { backgroundColor: state.isSelected ? '#4B0082' : '#f0f0f0' }
    })
  };

  return (
    <>
      <div className='pb-2'>
        {labelname && <label>{labelname}</label>}
      </div>
      <div className='w-100 d-flex'>
        <Select
          placeholder={placeholder}
          options={optionlist}
          value={optionlist.find(option => option.value === value) || null}
          onChange={handleChange}
          className='w-100'
          styles={customStyles}
          {...rest}
        />
      </div>
    </>
  );
};


//dropdownfix 
// const DropDownUI = ({ optionlist = [], className, name, labelname, placeholder, value, onChange, onKeyDown, autoFocus, disabled,...rest }) => {
//   const handleChange = (selectedOption) => {
//     const selectedValue = selectedOption.value;
//     if (selectedValue !== value) {
//       onChange({
//         ...value,
//         [name]: selectedValue
//       });
//     }
//   };

//   // Find the selected option based on the current value
//   const selectedOption = optionlist.find(option => option.value === value);
//   const customStyles = {
//     control: (provided, state) => ({
//       ...provided,
//       borderColor: '#4B0082',
//       outline: 'none',
//       backgroundColor: '#EFEFEF',
//       boxShadow: state.isFocused ? 'none' : provided.boxShadow,
//       '&:hover': {
//         borderColor: '#4B0082',
//       },
//     }),
//     option: (provided, state) => ({
//       ...provided,
//       backgroundColor: state.isSelected ? '#4B0082' : provided.backgroundColor,
//       '&:hover': {
//         backgroundColor: state.isSelected ? '#4B0082' : '#f0f0f0',
//       },
//     }),
//   };
//   return (
//     <>
//       <div className='pb-2'>
//         {labelname ? <label>{labelname}</label> : ""}
//       </div>

//       <div className='w-100 d-flex'>
//         <Select
//           placeholder={placeholder}
//           options={optionlist}
//           labelField='label'
//           valueField='value'
//           value={selectedOption} // Set the selected option
//           onChange={handleChange}
//           multi
//           className='w-100'
//           styles={customStyles}
//           onKeyDown={onKeyDown}
//           autoFocus={autoFocus}
//           disabled={disabled}
//           {...rest}
//         />
//       </div>
//     </>
//   );
// };



// const Calender = ({ setLabel, selectedDate, calenderlabel }) => {
//   const [startDate, setStartDate] = useState(selectedDate != null ? selectedDate : new Date());

//   return (
//     <>
//       <div className='pb-2 px-3'>
//         <label>{calenderlabel}</label>
//       </div>
//       <DatePicker
//         selected={startDate}
//         onChange={(date) => {
//           setStartDate(date);
//           setLabel(date, 'date_of_birth'); // Pass 'date_of_birth' as the field name
//         }}
//         dateFormat='dd-MM-yyyy'
//         className="w-100 form-cntrl"
//         selectsStart
//         startDate={startDate}
//       />
//     </>
//   );
// };



const Calender = ({ setLabel, selectedDate, calenderlabel, placeholder }) => {
  const [startDate, setStartDate] = useState(selectedDate ? new Date(selectedDate) : null);

  // Sync `startDate` with `selectedDate` whenever `selectedDate` changes
  useEffect(() => {
    setStartDate(selectedDate ? new Date(selectedDate) : null);
  }, [selectedDate]);

  return (
    <>
      <div className='pb-2 px-3'>
        <label>{calenderlabel}</label>
      </div>
      <DatePicker
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setLabel(date, 'date_of_birth'); // Pass 'date_of_birth' as the field name
        }}
        dateFormat='dd-MM-yyyy'
        className="w-100 form-cntrl"
        selectsStart
        startDate={startDate}
        placeholderText={placeholder}
      />
    </>
  );
};
export { TextInputForm, DropDown, DropDownUI, Calender, InstantModel, TextForm }