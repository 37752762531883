import axios from 'axios';
//   `http://192.168.1.3:50006/${url}`
const axiosRequest = async (method, url, data = null, params = {}, config = {}) => {
  const domainUrl = `https://api.vsagencyvnr.com/${url}`;

  try {
    const response = await axios({
      method,
      url: domainUrl, // Correct property, not "domainUrl"
      data,           // Used for POST/PUT requests
      params,         // Query parameters (used for GET, DELETE, etc.)
      ...config       // Allows additional configurations like headers, etc.
    });

    return response.data;  // Return the data from the response
  } catch (error) {
    console.error(`Error with ${method.toUpperCase()} request to ${url}:`, error);
    throw error;  // Re-throw the error to handle it elsewhere
  }
};

export default axiosRequest
