import React, { useEffect, useState } from "react";
import { Card, Button, Container, Row, Col, Modal } from "react-bootstrap";
import { FaCalendarDay, FaCalendarAlt, FaRegCalendarCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegCalendarMinus } from "react-icons/fa";
import axios from "axios";
const Dashboard = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", productTotals: [] });
  const [dash, newDash] = useState([]); // State for storing API data
  console.log("dash", dash);

  useEffect(() => {
    axios.get('https://api.vsagencyvnr.com/dashboard/webdashboard')
      .then(response => {
        newDash(response.data);  // Update the state with fetched data
        console.log(response.data);  // Log the data to console
      })
      .catch(error => {
        console.error('Error fetching the API data:', error);
      });
  }, []);

  const handleShowModal = (title, productTotals) => {
    setModalData({ title, productTotals });
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login")
    }
  }, [])
  return (
    <Container fluid>
      <div className='main my-3 ' style={{ height: "89vh" }}>
        <div>
          <Container fluid className="my-5 date-container">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={6} lg={3} className="mb-4">
                <Card className="date-card day" onClick={() => handleShowModal("Delivery Man Collection Amount", dash.deliveryAgentData?.productTotals)}>
                  <Card.Body>
                    <FaCalendarDay className="icon" />
                    <Card.Title>Delivery Man Over All Collection Amount</Card.Title>
                    <Card.Text>{dash.deliveryAgentData?.overallCollectedAmount}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-4">
                <Card className="date-card month" onClick={() => handleShowModal("Sales Man Collection Amount", dash.salesAgentData?.productTotals)}>
                  <Card.Body>
                    <FaCalendarAlt className="icon" />
                    <Card.Title>Sales Man Over All Collection  Amount</Card.Title>
                    <Card.Text>{dash.salesAgentData?.overallCollectedAmount}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              {/* <Col xs={12} sm={6} md={3} className="mb-4">
                <Card className="date-card year">
                  <Card.Body>
                    <FaRegCalendarCheck className="icon" />
                    <Card.Title>Delivery Man (Customer) No Pay</Card.Title>
                    <Card.Text>{dash.pendingPaymentCountdeliveryman}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className="mb-4">
                <Card className="date-card sales">
                  <Card.Body>
                    <FaRegCalendarMinus className="icon" />
                    <Card.Title>Sales Man (Customer) No Pay</Card.Title>
                    <Card.Text>{dash.pendingPaymentCountSalesman}</Card.Text>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>
          </Container>
          {/* Modal for showing productTotals data */}
          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{modalData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                {modalData.productTotals?.length > 0 ? (
                  <Row>
                    {modalData.productTotals.map((product, index) => (
                      <Col key={index} xs={12} className="mb-3">
                        <div className="product-card p-3 border rounded">
                          <h5>Product {index + 1}</h5>
                          <p><strong>Product Name:</strong> {product.product}</p>
                          <p><strong>{product.product} Amount:</strong> {product.collectedAmount}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <p>No product data available</p>
                )}
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Container >
  )
}

export default Dashboard