import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Spinner, Modal, Button } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { fetchAllBank, createBank, updateBank, deleteBank } from './Api';
import { FaEdit, FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { RiEdit2Fill } from "react-icons/ri";

const Bank = () => {
    const navigate = useNavigate();
    const [listBank, setListBank] = useState([]);;
    console.log('listBank', listBank);

    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({
        bank_img: "",
        bank_details: ""
    });
    console.log("formData", formData);
    const handleFileChangecustomerphoto = (event, inputName) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
            // Set the file in formData
            setFormData({
                ...formData,
                bank_img: file,
            });
        }
    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const data = await fetchAllBank();
            setListBank(data); // Set data directly
        } catch (error) {
            console.error("Error fetching users:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async () => {
        if (!formData.bank_img || !formData.bank_details) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing fields',
                text: 'All fields are required.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
            return;
        }

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("bank_img", formData.bank_img);
            formDataToSend.append("bank_details", formData.bank_details);

            if (editMode) {
                await updateBank(selectedUserId, formDataToSend);
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Bank details have been updated successfully!',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-ok-button-class',
                    },
                });
            } else {
                await createBank(formDataToSend);
                Swal.fire({
                    icon: 'success',
                    title: 'Created',
                    text: 'New Bank Details created successfully!',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-ok-button-class',
                    },
                });
            }

            setShowModal(false);
            setEditMode(false);
            setFormData({
                bank_img: "",
                bank_details: ""
            });
            fetchData();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `An error occurred: ${error.message}`,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
        }
    };



    const handleOpenModal = (user = null) => {
        setShowModal(true);
        if (user) {
            setEditMode(true);
            setSelectedUserId(user.bank_id); // Use user_id for update
            setFormData({
                bank_img: user.bank_img,
                bank_details: user.bank_details,
            });
            setSelectedImage(user.bank_img);
        } else {
            setEditMode(false);
            setFormData({
                bank_img: '',
                bank_details: ''
            });
            setSelectedImage(null);
        }
    };

    const handleDeleteUser = (userId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'my-confirm-button-class',
                cancelButton: 'my-cancel-button-class',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteBank(userId);
                    fetchData();
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'Bank Detail Deleted successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-ok-button-class',
                        },
                    })
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        `An error occurred while deleting: ${error.message}`,
                        'error'
                    );
                }
            }
        });
    };


    const columns = [
        {
            field: 'bank_img',
            headerName: 'QR Image',
            width: 200,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="QR"
                    style={{
                        width: '60px',     // Adjust width to control the size
                        height: '60px',    // Adjust height to control the size
                        borderRadius: '50%', // Makes the image round
                        objectFit: 'cover', // Ensures the image covers the entire area
                    }}
                />
            ),
        },
        { field: 'bank_details', headerName: 'Bank Details', width: 800 },

        {
            field: 'actions',
            headerName: 'Actions',
            width: 595,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <RiEdit2Fill className='userEdit icon-edit'
                        onClick={() => handleOpenModal(params.row)}

                    />
                    <MdDelete className='userEdit icon-edit'
                        onClick={() => handleDeleteUser(params.row.bank_id)}
                    />
                </div>
            ),
        },
    ];

    // useEffect(()=>{
    //     if(!localStorage.getItem("userid")){
    //       navigate("/login")
    //     }
    //   },[])
    return (
        <Container fluid>
            <div className='main my-3 ' style={{ height: "89vh" }}>
                <div>
                    <Row>
                        <Col lg="6" md="6" xs="6">
                            <div className="page-nav py-3 h5">
                                <h3>Bank Details</h3>
                            </div>
                        </Col>
                        <Col lg="6" md="6" xs="6">
                            <div className='text-end py-3'>
                                <Button variant="contained" onClick={() => handleOpenModal()} className='add-new'>
                                    Add New
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={listBank}
                                    columns={columns}
                                    loading={loading}
                                    autoPageSize
                                    getRowId={(row) => row.bank_id}
                                    disableSelectionOnClick
                                    sx={{
                                        '& .MuiDataGrid-columnHeader': {
                                            backgroundColor: '#4B0082',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                '.MuiSvgIcon-root': {
                                                    color: 'white', // Change the icon color to white on hover
                                                },
                                            },
                                        },
                                        '& .MuiDataGrid-columnHeader--alignLeft': {
                                            textAlign: 'left',
                                        },
                                        '& .MuiDataGrid-columnHeader--sortable': {
                                            cursor: 'pointer',
                                        },
                                        '& .MuiDataGrid-withBorderColor': {
                                            borderColor: '#530004',
                                        },
                                        '& .MuiDataGrid-scrollbar--vertical': {
                                            display: 'none',
                                        },
                                    }}
                                />


                            </div>
                        </Col>
                    </Row>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{editMode ? "Edit Bank" : "Add New Bank"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Col lg="12" className="d-flex justify-content-center">
                                <div style={{ position: "relative", display: "inline-block" }}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileChangecustomerphoto}
                                        style={{
                                            display: "none", // Hide the default input
                                        }}
                                        id="file-upload" // ID for label reference
                                    />
                                    <label
                                        htmlFor="file-upload"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "80px", // Adjust the size as needed
                                            height: "80px", // Adjust the size as needed
                                            borderRadius: "50%", // Makes the label round
                                            border: "2px solid #000", // Optional: border for the round input
                                            backgroundColor: "#f0f0f0", // Background color
                                            cursor: "pointer",
                                        }}
                                    >
                                        {selectedImage ? (
                                            <img
                                                src={selectedImage}
                                                alt="Selected"
                                                style={{
                                                    width: "100%", // Ensures the image fills the container
                                                    height: "100%", // Ensures the image fills the container
                                                    borderRadius: "50%", // Make image round
                                                    objectFit: "cover", // Ensures the image covers the entire circle
                                                }}
                                            />
                                        ) : (
                                            <span style={{ fontSize: "20px" }}>+</span> // Optional: plus icon for uploading
                                        )}
                                    </label>
                                </div>
                            </Col>
                            <TextField
                                label="Bank Details"
                                name="bank_details"
                                value={formData.bank_details}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                multiline
                                rows={4}

                            />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)} className='add-new'>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} className='add-new'>
                                {editMode ? "Update" : "Submit"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div >
            {/* <div className="daily-generation-section">
                <div className="fixed-button-bar">
                    <div className="d-flex">
                        <div>
                            kannan
                        </div>
                        <div className=''>
                            version
                        </div>
                    </div>
                </div>
            </div> */}
        </Container >
    );
};

export default Bank;
