import React, { useState, useEffect, useContext } from "react";
import {Container,Row,Col,Table,Spinner,Alert,Button,} from "react-bootstrap";
import * as XLSX from "xlsx";
import { AiOutlineUpload } from "react-icons/ai";
import { TextInputForm, DropDownUI, Calender } from "../components/Forms";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { UserContext } from "../context/userContext";
// const types = [
//   {
//     label: "PEPSI",
//     value: "pepsi",
//   },
//   {
//     label: "BRITANIA",
//     value: "britannia",
//   },
//   {
//     label: "COLGATE",
//     value: "colgate",
//   },
//   {
//     label: "LAYS",
//     value: "lays",
//   }
// ];
const types = [
  { label: "PEPSI", value: "pepsi", roles: ["admin", "pepsimanager"] },
  { label: "BRITANIA", value: "britannia", roles: ["admin", "britaniamanager"] },
  { label: "COLGATE", value: "colgate", roles: ["admin", "colgatemanager"] },
  { label: "LAYS", value: "lays", roles: ["admin", "laysmanager"] },
];
const ExcelUpload = () => {
  const { user } = useContext(UserContext)
  console.log('userContext',user);
  
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filedetailsList, setFileDetailsList] = useState([]);
  const [onclickStatus, setOnClickStatus] = useState(false)
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [formData, setFormData] = useState({
    js_data: "",
    productname: "",
  });
  const [filename, setFilename] = useState(null);
  console.log("formData", formData);
  const filteredTypes = [
    { label: "PEPSI", value: "pepsi", roles: ["admin", "pepsimanager"] },
    { label: "BRITANIA", value: "britannia", roles: ["admin", "britaniamanager"] },
    { label: "COLGATE", value: "colgate", roles: ["admin", "colgatemanager"] },
    { label: "LAYS", value: "lays", roles: ["admin", "laysmanager"] },
  ].filter(
    (type) =>
      user?.role && type.roles.includes(user.role) // Ensure `user` and `user.role` exist
  );
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    // Listen for network changes
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Function to convert Excel to JSON format
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFilename(file.name);
      setLoading(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        setExcelData(jsonData);
        setFormData((prevFormData) => ({
          ...prevFormData,
          js_data: JSON.stringify(jsonData),
        }));
        setLoading(false);
        toast.success("Excel file uploaded successfully!");
      };
      reader.onerror = () => {
        setError("Failed to read file.");
        setLoading(false);
        toast.error("Error reading the file!");
      };
      reader.readAsArrayBuffer(file);
    } else {
      setError("No file selected.");
      toast.error("No file selected!");
    }
  };

  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const fetchData = async () => {
    try {
      // Construct a JSON object with the necessary data localStorage.getItem("userid")
      const payload = {
        excel_data: JSON.parse(formData.js_data), // JSON string of Excel data
        productname: formData.productname,
        userid: localStorage.getItem("userid"),
        file_name: filename,
        created_role: "manager"
      };

      // Send the request with the JSON payload "
      const response = await fetch(
        "https://api.vsagencyvnr.com/delivery/createdelivery",
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload), // Convert payload to JSON
        }
      );

      // Check if the response status is OK (200)
      if (response.status === 200) {
        const responseData = await response.json();
        setOnClickStatus(curr => !curr)
        // Check the response data for success status
        if (responseData.status === 200) {
          // Display success alert
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Manager Excel file uploaded successfully!",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "my-ok-button-class", // Add your custom class for styling
            },
          });

          setUserData(responseData); // Update user data with response data
        } else {
          throw new Error(responseData.msg); // Handle error based on response message
        }
      } else {
        throw new Error("Failed to fetch data: " + response.statusText); // Handle non-200 response
      }
    } catch (error) {
      console.error("Error fetching report data:", error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!filename) {
      toast.error("Please upload a file before submitting!");
      return;
    }

    if (!formData.productname) {
      toast.error("Please select a product name before submitting!");
      return;
    }

    fetchData();
    setFilename(null); // Reset the filename
    setFormData((prev) => ({
      ...prev,
      productname: "", // Reset the product name field
   
    }));
  };

  useEffect(() => {
    axios
      .get("https://api.vsagencyvnr.com/filedetails/filedetailslist?created_role=manager")
      .then((data) => setFileDetailsList(data.data.data))
      .catch((error) => {
        console.log("error", error);
      });
  }, [onclickStatus]);
  console.log("files list", filedetailsList);
  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">Manager Upload EXCEL</div>
            </Col>
            <Col lg={12}>
              <div className="justify-content-center d-flex">
                <label htmlFor="file-upload" className="file-upload-label">
                  <AiOutlineUpload size={24} className="upload-icon" />
                  <span>Upload Excel File</span>
                </label>
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileUpload}
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col lg={12} className="text-center my-3">
              {filename ? (
                <p>
                  <strong>Uploaded File:</strong> {filename}
                </p>
              ) : (
                <p className="text-muted">No file selected</p>
              )}
            </Col>
            <Col lg="3" md="12" xs="12" className="py-3">
              <DropDownUI
                optionlist={filteredTypes}
                placeholder="productname"
                labelname="productname"
                name="productname"
                value={formData.productname}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    productname: updatedFormData,
                  })
                }
              ></DropDownUI>
            </Col>
            <Col lg={3} md="12" xs="12" className="py-5">
              <Button className="excelsubmit" onClick={handleSubmit}   disabled={!filename || !formData.productname}>
                Submit
              </Button>
            </Col>
          </Row>
          <Container>
            <Row className="gy-4">
              {" "}
              {/* Adds vertical spacing between rows */}
              {Array.isArray(filedetailsList) &&
                filedetailsList.map((sin, index) => (
                  <Col sm={12} md={6} lg={4} key={index}>
                    {" "}
                    {/* Adjust columns for different screen sizes */}
                    <div className="card h-100 shadow-sm p-3">
                      {" "}
                      {/* Card layout with shadow */}
                      <div className="card-body">
                        <h5 className="card-title text-center font-weight-bold text-primary">
                          {sin.file_name}
                        </h5>
                        <p className="card-text text-center text-muted">
                          Product: {sin.product_name}
                        </p>
                        <p className="card-text text-center">
                          File Count:{" "}
                          <span className="badge bg-info">
                            {sin.file_count}
                          </span>{" "}
                          {/* Badge for emphasis */}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </Container>
          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" />
              {!isOnline && (
                <Alert variant="warning" className="ml-2">
                  Network is down. Please wait until it reconnects.
                </Alert>
              )}
            </div>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}

          <ToastContainer position="top-center" autoClose={3000} />

          {/* {isOnline && excelData.length > 0 && (
            <div
              style={{
                overflow: "auto",
                maxHeight: "780px",
                maxWidth: "1880px",
              }}
              className="mt-2"
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {Object.keys(excelData[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((cell, i) => (
                        <td key={i}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )} */}
        </div>
      </Container>
    </div>
  );
};

export default ExcelUpload;

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Table, Spinner, Alert } from 'react-bootstrap';
// import * as XLSX from 'xlsx';
// import { AiOutlineUpload } from 'react-icons/ai';

// const ExcelUpload = () => {
//     const [excelData, setExcelData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [isOnline, setIsOnline] = useState(navigator.onLine);

//     useEffect(() => {
//         // Listen for network changes
//         const handleOnline = () => {
//             setIsOnline(true);
//         };

//         const handleOffline = () => {
//             setIsOnline(false);
//         };

//         window.addEventListener('online', handleOnline);
//         window.addEventListener('offline', handleOffline);

//         return () => {
//             window.removeEventListener('online', handleOnline);
//             window.removeEventListener('offline', handleOffline);
//         };
//     }, []);

//     const handleFileUpload = (e) => {
//         const file = e.target.files[0];

//         if (file) {
//             setLoading(true);
//             setError(null);
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 try {
//                     const binaryStr = event.target.result;
//                     const workbook = XLSX.read(binaryStr, { type: 'binary' });

//                     // Assuming you want to read the first sheet
//                     const worksheetName = workbook.SheetNames[0];
//                     const worksheet = workbook.Sheets[worksheetName];

//                     // Convert the sheet to JSON
//                     const data = XLSX.utils.sheet_to_json(worksheet);
//                     setExcelData(data);
//                 } catch (err) {
//                     setError('Error reading Excel file. Please try again.');
//                 } finally {
//                     setLoading(false);
//                 }
//             };
//             reader.readAsBinaryString(file);
//         } else {
//             setError('No file selected.');
//         }
//     };

//     return (
//         <div>
//             <Container fluid>
//                 <div className='main my-3'>
//                     <Row>
//                         <Col lg={2}>
//                             <label htmlFor="file-upload" className="file-upload-label">
//                                 <AiOutlineUpload size={24} className="upload-icon" />
//                                 <span>Upload Excel File</span>
//                             </label>
//                             <input
//                                 type="file"
//                                 id="file-upload"
//                                 onChange={handleFileUpload}
//                                 accept=".xlsx, .xls"
//                                 style={{ display: 'none' }}
//                             />
//                         </Col>
//                     </Row>

//                     {loading && (
//                         <div className="d-flex justify-content-center mt-3">
//                             <Spinner animation="border" />
//                             {!isOnline && (
//                                 <Alert variant="warning" className="ml-2">
//                                     Network is down. Please wait until it reconnects.
//                                 </Alert>
//                             )}
//                         </div>
//                     )}

//                     {error && (
//                         <Alert variant="danger" className="mt-3">
//                             {error}
//                         </Alert>
//                     )}

//                     {isOnline && excelData.length > 0 && (
//                         <div style={{ overflow: "auto", maxHeight: "740px", maxWidth: "1880px" }} className='mt-2'>
//                             <Table striped bordered hover>
//                                 <thead>
//                                     <tr>
//                                         {Object.keys(excelData[0]).map((key) => (
//                                             <th key={key}>{key}</th>
//                                         ))}
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {excelData.map((row, index) => (
//                                         <tr key={index}>
//                                             {Object.values(row).map((cell, i) => (
//                                                 <td key={i}>{cell}</td>
//                                             ))}
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </Table>
//                         </div>
//                     )}
//                 </div>
//             </Container>
//         </div>
//     );
// };

// export default ExcelUpload;

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Table, Spinner, Alert } from 'react-bootstrap';
// import * as XLSX from 'xlsx';
// import { AiOutlineUpload } from 'react-icons/ai';

// const ExcelUpload = () => {
//     const [excelData, setExcelData] = useState([]);
//     const [editedData, setEditedData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [isOnline, setIsOnline] = useState(navigator.onLine);

//     useEffect(() => {
//         // Listen for network changes
//         const handleOnline = () => {
//             setIsOnline(true);
//         };

//         const handleOffline = () => {
//             setIsOnline(false);
//         };

//         window.addEventListener('online', handleOnline);
//         window.addEventListener('offline', handleOffline);

//         return () => {
//             window.removeEventListener('online', handleOnline);
//             window.removeEventListener('offline', handleOffline);
//         };
//     }, []);

//     const handleFileUpload = (e) => {
//         const file = e.target.files[0];

//         if (file) {
//             setLoading(true);
//             setError(null);
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 try {
//                     const binaryStr = event.target.result;
//                     const workbook = XLSX.read(binaryStr, { type: 'binary' });

//                     // Assuming you want to read the first sheet
//                     const worksheetName = workbook.SheetNames[0];
//                     const worksheet = workbook.Sheets[worksheetName];

//                     // Convert the sheet to JSON
//                     const data = XLSX.utils.sheet_to_json(worksheet);
//                     setExcelData(data);
//                     setEditedData(data); // Initialize editedData with the uploaded data
//                 } catch (err) {
//                     setError('Error reading Excel file. Please try again.');
//                 } finally {
//                     setLoading(false);
//                 }
//             };
//             reader.readAsBinaryString(file);
//         } else {
//             setError('No file selected.');
//         }
//     };

//     const handleInputChange = (rowIndex, key, value) => {
//         const updatedData = editedData.map((row, index) => {
//             if (index === rowIndex) {
//                 return { ...row, [key]: value };
//             }
//             return row;
//         });
//         setEditedData(updatedData);
//     };

//     return (
//         <div>
//             <Container fluid>
//                 <div className='main my-3'>
//                     <Row>
//                         <Col lg={2}>
//                             <label htmlFor="file-upload" className="file-upload-label">
//                                 <AiOutlineUpload size={24} className="upload-icon" />
//                                 <span>Upload Excel File</span>
//                             </label>
//                             <input
//                                 type="file"
//                                 id="file-upload"
//                                 onChange={handleFileUpload}
//                                 accept=".xlsx, .xls"
//                                 style={{ display: 'none' }}
//                             />
//                         </Col>
//                     </Row>

//                     {loading && (
//                         <div className="d-flex justify-content-center mt-3">
//                             <Spinner animation="border" />
//                             {!isOnline && (
//                                 <Alert variant="warning" className="ml-2">
//                                     Network is down. Please wait until it reconnects.
//                                 </Alert>
//                             )}
//                         </div>
//                     )}

//                     {error && (
//                         <Alert variant="danger" className="mt-3">
//                             {error}
//                         </Alert>
//                     )}

//                     {isOnline && editedData.length > 0 && (
//                         <div style={{ overflow: "auto", maxHeight: "740px", maxWidth: "1880px" }} className='mt-2'>
//                             <Table striped bordered hover>
//                                 <thead>
//                                     <tr>
//                                         {Object.keys(editedData[0]).map((key) => (
//                                             <th key={key}>{key}</th>
//                                         ))}
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {editedData.map((row, rowIndex) => (
//                                         <tr key={rowIndex}>
//                                             {Object.entries(row).map(([key, value], i) => (
//                                                 <td key={i}>
//                                                     <input
//                                                         type="text"
//                                                         value={value}
//                                                         onChange={(e) => handleInputChange(rowIndex, key, e.target.value)}
//                                                         style={{ width: '100%' }}
//                                                     />
//                                                 </td>
//                                             ))}
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </Table>
//                         </div>
//                     )}
//                 </div>
//             </Container>
//         </div>
//     );
// };

// export default ExcelUpload;

// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Table, Spinner, Alert } from 'react-bootstrap';
// import * as XLSX from 'xlsx';
// import { AiOutlineUpload } from 'react-icons/ai';
// import { ClickButton } from '../components/Buttons';

// const ExcelUpload = () => {
//     const [excelData, setExcelData] = useState([]);
//     const [editedData, setEditedData] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);
//     const [isOnline, setIsOnline] = useState(navigator.onLine);

//     useEffect(() => {
//         // Listen for network changes
//         const handleOnline = () => {
//             setIsOnline(true);
//         };

//         const handleOffline = () => {
//             setIsOnline(false);
//         };

//         window.addEventListener('online', handleOnline);
//         window.addEventListener('offline', handleOffline);

//         return () => {
//             window.removeEventListener('online', handleOnline);
//             window.removeEventListener('offline', handleOffline);
//         };
//     }, []);

//     const handleFileUpload = (e) => {
//         const file = e.target.files[0];

//         if (file) {
//             setLoading(true);
//             setError(null);
//             const reader = new FileReader();
//             reader.onload = (event) => {
//                 try {
//                     const binaryStr = event.target.result;
//                     const workbook = XLSX.read(binaryStr, { type: 'binary' });

//                     // Assuming you want to read the first sheet
//                     const worksheetName = workbook.SheetNames[0];
//                     const worksheet = workbook.Sheets[worksheetName];

//                     // Convert the sheet to JSON
//                     const data = XLSX.utils.sheet_to_json(worksheet);
//                     setExcelData(data);
//                     setEditedData(data); // Initialize editedData with the uploaded data
//                 } catch (err) {
//                     setError('Error reading Excel file. Please try again.');
//                 } finally {
//                     setLoading(false);
//                 }
//             };
//             reader.readAsBinaryString(file);
//         } else {
//             setError('No file selected.');
//         }
//     };

//     const handleInputChange = (rowIndex, key, value) => {
//         const updatedData = editedData.map((row, index) => {
//             if (index === rowIndex) {
//                 return { ...row, [key]: value };
//             }
//             return row;
//         });
//         setEditedData(updatedData);
//     };

//     const handleExport = () => {
//         const worksheet = XLSX.utils.json_to_sheet(editedData);
//         const workbook = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

//         // Generate Excel file and prompt download
//         XLSX.writeFile(workbook, "ExportedData.xlsx");
//     };

//     return (
//         <div>
//             <Container fluid>
//                 <div className='main my-3'>
//                     <Row>
//                         <Col lg={2}>
//                             <label htmlFor="file-upload" className="file-upload-label">
//                                 <AiOutlineUpload size={24} className="upload-icon" />
//                                 <span>Upload Excel File</span>
//                             </label>
//                             <input
//                                 type="file"
//                                 id="file-upload"
//                                 onChange={handleFileUpload}
//                                 accept=".xlsx, .xls"
//                                 style={{ display: 'none' }}
//                             />
//                         </Col>
//                         <Col lg={2}>
//                             <ClickButton onClick={handleExport} label={"Export Excel File"} />

//                         </Col>
//                     </Row>

//                     {loading && (
//                         <div className="d-flex justify-content-center mt-3">
//                             <Spinner animation="border" />
//                             {!isOnline && (
//                                 <Alert variant="warning" className="ml-2">
//                                     Network is down. Please wait until it reconnects.
//                                 </Alert>
//                             )}
//                         </div>
//                     )}

//                     {error && (
//                         <Alert variant="danger" className="mt-3">
//                             {error}
//                         </Alert>
//                     )}

//                     {isOnline && editedData.length > 0 && (
//                         <div style={{ overflow: "auto", maxHeight: "740px", maxWidth: "1880px" }} className='mt-2'>
//                             <Table striped bordered hover>
//                                 <thead>
//                                     <tr>
//                                         {Object.keys(editedData[0]).map((key) => (
//                                             <th key={key}>{key}</th>
//                                         ))}
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {editedData.map((row, rowIndex) => (
//                                         <tr key={rowIndex}>
//                                             {Object.entries(row).map(([key, value], i) => (
//                                                 <td key={i}>
//                                                     <input
//                                                         type="text"
//                                                         value={value}
//                                                         onChange={(e) => handleInputChange(rowIndex, key, e.target.value)}
//                                                         style={{
//                                                             width: '200px',
//                                                             whiteSpace: 'nowrap',
//                                                             overflow: 'hidden',
//                                                             textOverflow: 'ellipsis',
//                                                         }}
//                                                     />
//                                                 </td>
//                                             ))}
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </Table>
//                         </div>
//                     )}
//                 </div>
//             </Container>
//         </div>
//     );
// };

// export default ExcelUpload;
