import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Alert, Spinner } from 'react-bootstrap'
import { DropDownUI, TextInputForm, TextForm } from '../components/Forms'
import { VscEyeClosed, VscEye } from "react-icons/vsc";
import { ClickButton } from '../components/Buttons';
const Login = ({ onLogin }) => {
  const [phone_number, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  const handleLogin = async () => {
    try {
      if (phone_number === "" || password === "") {
        throw new Error('Username Or Password is Empty');
      }
      if (!phone_number || !password) {
        throw new Error('Username and Password are required');
      }


      const loginData = {
        phone_number: phone_number,
        password: password
      };

      const response = await fetch('https://api.vsagencyvnr.com/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(loginData),
      });

      // if (!response.ok) {
      //   throw new Error('Login failed');
      // }

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.status !== 200) {
        setError(responseData.msg);
      } else if (responseData.status === 200) {
        // alert(responseData)
        localStorage.setItem("token", responseData.token)
        localStorage.setItem("userid", responseData.user.user_id)
        onLogin();
        const userRole = responseData.user.role;
      if (userRole === 'admin') {
        navigate('/console/user');
      } else if (userRole === 'mis') {
        navigate('/console/mis/uploadexcel');
      } else if (userRole === 'pepsimanager' ||userRole === 'britaniamanager' ||userRole === 'colgatemanager' ||userRole === 'laysmanager'){
        navigate('/console/uploadexcel');
      } else if (userRole === 'collectionagent') {
        navigate('/console/collectionagent');
      }
      else {
        setError('You do not have permission to access this page'); // Optional message for other roles
      }
      }
    } catch (error) {
      console.error('Login error:', error.message);
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };
  const handlePhoneNumberKeyDown = (e) => {
    if (e.key === "Enter") {
      passwordInputRef.current.focus();  // Focus on the password input when Enter is pressed
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
  return (
    <div className='login-bg'>
      <Container fluid className="d-flex justify-content-center align-items-center min-vh-100 ssc">
        <Row className='justify-content-center w-100'>
          <Col lg='4' md="6" xs='12' className='kannan'>
            <div className=' border p-4 shadow-sm rounded kannan1'>
              <Row>
                <Col lg='12'>
                  <div className='text-center'>
                    <img src={require('../assests/images/vslogo PNG image (1).png')} className='img-fluid login-logo' alt='' />
                  </div>
                </Col>
                <Col lg='12'>
                <div className='text-center fs-3 py-2'>Vs Agency</div>
                  <div className='text-center fs-3 py-2'>Signin to Your Account</div>
                 
                </Col>
                <Col lg='12'>
                  <div className='py-3'>

                  </div>
                </Col>
                <Col lg='12'>
                  <div className='py-3'>
                    <TextForm
                      labelname={"PhoneNumber"}
                      placeholder={"PhoneNumber"}
                      value={phone_number}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onKeyDown={handlePhoneNumberKeyDown}
                    />
                  </div>
                </Col>
                <Col lg='12'>
                  <div className='py-3'>
                    <TextForm
                      labelname={"Password"}
                      placeholder={"Password"}
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      suffix_icon={showPassword ? <VscEye onClick={() => setShowPassword(false)} /> : <VscEyeClosed onClick={() => setShowPassword(true)} />}
                      onKeyDown={handleKeyDown}
                      ref={passwordInputRef}
                    />
                  </div>
                </Col>
              </Row>
              <div className='py-3 text-center mb-5'>
                <ClickButton
                  label={loading ? <Spinner animation="border" size="sm" /> : <>Login</>} // Show spinner if loading
                  onClick={handleLogin}
                  disabled={loading} // Disable button during loading
                />
              </div>
              {error && (
                <Alert variant="danger">{error}</Alert> // Render error alert banner if error state is not null
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Login