import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ClickButton } from "../components/Buttons";
import { DropDownUI, Calender } from "../components/Forms";
import moment from "moment";
import axiosRequest from "../service/api";
import { Grid, Input, InputLabel, FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CollectionWorkPdf from "./CollectionWorkPdf";
import * as XLSX from 'xlsx';
const product = [
  { label: "PEPSI", value: "pepsi" },
  { label: "BRITANNIA", value: "britannia" },
  { label: "COLGATE", value: "colgate" },
  { label: "LAYS", value: "lays" },
];
const roll = [
  { label: "Delivery Man", value: "deliveryagent" },
  { label: "Salesman", value: "salesagent" },
];



const CollectionAgent = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: "",
    product: "",
    roll: "",
    personid: "",
    payment_method: "",
  });

  const [personId, setPersonId] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({ ...formData, [fieldName]: value });
  };

  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"),
    });
  };

  const fetchUsersByRole = async () => {
    try {
      const role = `${formData.roll}`;
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axiosRequest(
        "get",
        "user/filterbyrole",
        null,
        { role },
        { headers }
      );
      if (Array.isArray(response.users)) {
        const Login_id_array = response.users.map((user) => ({
          label: user.login_id,
          value: user.login_id,
        }));
        setPersonId(Login_id_array);
      } else {
        console.log("No users found with the specified role.");
      }
    } catch (error) {
      console.error("Error fetching users by role:", error);
    }
  };
  console.log("vijay FormData", formData);
  const [totalDelivery, setTotalDelivery] = useState([])
  console.log('totalDelivery', totalDelivery)
  const collectionAgentDataArray = async () => {

    setLoading(true);
    const { date, roll, personid, product } = formData;
    const headers = {
      Authorization: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axiosRequest(
        "get",
        "collectionagent/getdeliverymendetailsdate",
        null,
        {
          date,
          role: roll,
          loginId: personid,
          productname: product
        },
        { headers }
      );
      setTotalDelivery(response)

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const downloadExcel = () => {
  //   // Create Sheet 1 for delivery data
  //   const deliveryData = totalDelivery.delivery.map(item => ({
  //     amount_paid: item.amount_paid,
  //     beat_code_name: item.beat_code_name,
  //     customer_name: item.customer_name,
  //     invoice_date: moment(item.invoice_date).format("YYYY-MM-DD"), // Format date
  //     payment_method: item.payment_method,
  //     product_status: item.product_status,
  //   }));

  //   const ws1 = XLSX.utils.json_to_sheet(deliveryData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");

  //   // Create Sheet 2 for case summary data
  //   const caseSummaryData = [
  //     { Description: "500", Count: totalDelivery.total_case_summery[500] || 0 },
  //     { Description: "200", Count: totalDelivery.total_case_summery[200] || 0 },
  //     { Description: "100", Count: totalDelivery.total_case_summery[100] || 0 },
  //     { Description: "50", Count: totalDelivery.total_case_summery[50] || 0 },
  //     { Description: "20", Count: totalDelivery.total_case_summery[20] || 0 },
  //     { Description: "10", Count: totalDelivery.total_case_summery[10] || 0 },
  //     { Description: "5", Count: totalDelivery.total_case_summery[5] || 0 },
  //     { Description: "2", Count: totalDelivery.total_case_summery[2] || 0 },
  //     { Description: "1", Count: totalDelivery.total_case_summery[1] || 0 },
  //   ];

  //   const ws2 = XLSX.utils.json_to_sheet(caseSummaryData);
  //   XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");

  //   // Export the workbook
  //   XLSX.writeFile(wb, "Delivery_Report.xlsx");
  // };

{/*----*/}
  
  // const downloadExcel = () => {
  //   // Create Sheet 1 for delivery data

  //   const deliveryData = totalDelivery.delivery.map(item => {
  //     return formData.roll === 'salesagent' ? {
  //       InvoiceNumber: item.invoice_number,
  //       CustomerName: item.customer_name,
  //       InvoiceAmount: item.amount,
  //       CollectedAmount: item.amount_paid,
  //       OutStandingAmount: item.remaining_balance,
  //       PaymentMethod: item.payment_method,
  //       ReceiptNumber: item.receipt_number,
  //       SalesMan: item.user_name,
  //       Remarks: item.product_remarks
  //     } : {
  //       InvoiceNumber: item.invoice_number,
  //       CustomerName: item.customer_name,
  //       CustomerCode: item.customer_code,
  //       InvoiceAmount: item.amount,
  //       CollectedAmount: item.amount_paid,
  //       OutStandingAmount: item.remaining_balance,
  //       PaymentMethod: item.payment_method || "return",
  //       ReceiptNumber: item.receipt_number,
  //       SalesMan: item.incharge,

  //     };

  //   });
  //   deliveryData.sort((a, b) => a.SalesMan.localeCompare(b.SalesMan));
  //   const ws1 = XLSX.utils.json_to_sheet(deliveryData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");

  //   // Create Sheet 2 for case summary data
  //   const caseSummaryData = [
  //     { Description: "500", Count: totalDelivery.expenseTotalSummary[500] || 0 },
  //     { Description: "200", Count: totalDelivery.expenseTotalSummary[200] || 0 },
  //     { Description: "100", Count: totalDelivery.expenseTotalSummary[100] || 0 },
  //     { Description: "50", Count: totalDelivery.expenseTotalSummary[50] || 0 },
  //     { Description: "20", Count: totalDelivery.expenseTotalSummary[20] || 0 },
  //     { Description: "10", Count: totalDelivery.expenseTotalSummary[10] || 0 },
  //     { Description: "5", Count: totalDelivery.expenseTotalSummary[5] || 0 },
  //     { Description: "2", Count: totalDelivery.expenseTotalSummary[2] || 0 },
  //     { Description: "1", Count: totalDelivery.expenseTotalSummary[1] || 0 },
  //   ];

  //   // Calculate amount for each row and create a new array with amounts
  //   const formattedCaseSummaryData = caseSummaryData.map(item => ({
  //     Description: item.Description,
  //     Count: item.Count,
  //     Amount: item.Description * item.Count, // Multiply Description by Count for Amount
  //   }));

  //   const ws2 = XLSX.utils.json_to_sheet(formattedCaseSummaryData);
  //   XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");

  //   // Export the workbook
  //   XLSX.writeFile(wb, "Delivery_Report.xlsx");
  // };

  const downloadExcel = () => {
    // Create Sheet 1 for delivery data
    const deliveryData = totalDelivery.delivery.map(item => {
      // Apply the payment method filter only when formData.roll === 'salesagent'
      if (formData.roll === 'salesagent') {
        // Define valid payment methods
        const validPaymentMethods = ['cash', 'credit', 'gpay', 'check'];
  
        // Only include items with valid payment_method
        if (!validPaymentMethods.includes(item.payment_method?.toLowerCase())) {
          return null; // Return null to filter out the item
        }
      }
  
      // Return the appropriate data for 'salesagent' and other roles
      return formData.roll === 'salesagent' ? {
        InvoiceNumber: item.invoice_number,
        CustomerName: item.customer_name,
        InvoiceAmount: item.amount,
        CollectedAmount: item.amount_paid,
        OutStandingAmount: item.remaining_balance,
        PaymentMethod: item.payment_method,
        ReceiptNumber: item.receipt_number || '-',
        SalesMan: item.user_name,
        Remarks: item.product_remarks || '-',
      } : {
        InvoiceNumber: item.invoice_number,
        CustomerName: item.customer_name,
        CustomerCode: item.customer_code,
        InvoiceAmount: item.amount,
        CollectedAmount: item.amount_paid,
        OutStandingAmount: item.remaining_balance,
        PaymentMethod: item.payment_method || "return",
        ReceiptNumber: item.receipt_number || '-',
        SalesMan: item.incharge,
      };
    }).filter(item => item !== null); // Remove null values (filtered items)
  
    // Sort by SalesMan
    deliveryData.sort((a, b) => a.SalesMan.localeCompare(b.SalesMan));
  
    const ws1 = XLSX.utils.json_to_sheet(deliveryData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "Delivery Data");
  
    // Create Sheet 2 for case summary data
    const caseSummaryData = [
      { Description: "500", Count: totalDelivery.expenseTotalSummary[500] || 0 },
      { Description: "200", Count: totalDelivery.expenseTotalSummary[200] || 0 },
      { Description: "100", Count: totalDelivery.expenseTotalSummary[100] || 0 },
      { Description: "50", Count: totalDelivery.expenseTotalSummary[50] || 0 },
      { Description: "20", Count: totalDelivery.expenseTotalSummary[20] || 0 },
      { Description: "10", Count: totalDelivery.expenseTotalSummary[10] || 0 },
      { Description: "5", Count: totalDelivery.expenseTotalSummary[5] || 0 },
      { Description: "2", Count: totalDelivery.expenseTotalSummary[2] || 0 },
      { Description: "1", Count: totalDelivery.expenseTotalSummary[1] || 0 },
    ];
  
    // Calculate amount for each row and create a new array with amounts
    const formattedCaseSummaryData = caseSummaryData.map(item => ({
      Description: item.Description,
      Count: item.Count,
      Amount: item.Description * item.Count, // Multiply Description by Count for Amount
    }));
  
    const ws2 = XLSX.utils.json_to_sheet(formattedCaseSummaryData);
    XLSX.utils.book_append_sheet(wb, ws2, "Case Summary");
  
    // Export the workbook
    XLSX.writeFile(wb, "Delivery_Report.xlsx");
  };
  
  useEffect(() => {
    fetchUsersByRole();
  }, [formData.roll]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      personid: "",
    }));
  }, [formData.roll]);
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);
  console.log("formData", formData.roll);
  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">Collection Data</div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <Calender
                  setLabel={(date) => setLabel(date, "date")}
                  selectedDate={formData.date}
                  calenderlabel="Date"
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={product}
                  placeholder="Product"
                  labelname="Product"
                  name="product"
                  value={formData.product}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      product: updatedFormData,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={roll}
                  placeholder="Roll"
                  labelname="Roll"
                  name="roll"
                  value={formData.roll}
                  onChange={(updatedFormData) =>
                    setFormData({ ...formData, roll: updatedFormData })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={personId}
                  placeholder="Person Id"
                  labelname="Person Id"
                  name="personid"
                  value={formData.personid}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      personid: updatedFormData,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="mt-4 justify-content-end">
                <div className="px-2">
                  <ClickButton
                    onClick={collectionAgentDataArray}
                    label={<>SUBMIT</>}
                    className="create-btn"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* Other form fields */}
            <Col lg="12" md="12" xs="12" className="mt-5">
              {totalDelivery.collectionAgentSubmisson === true ? (
                <>
                  {/* Download the PDF */}
                  <PDFDownloadLink className="mx-5"
                    document={<CollectionWorkPdf totalDelivery={totalDelivery} roll={formData.roll} />}
                    fileName="Delivery_Report.pdf"
                  >
                    {({ loading }) =>
                      loading ? (
                        <ClickButton label="Loading PDF..." className="success-btn" />
                      ) : (
                        <ClickButton label="Download PDF" className="success-btn" />
                      )
                    }
                  </PDFDownloadLink>
                  {console.log("...form", formData.roll)}
                  {/* Inline preview (optional) */}
                  <BlobProvider document={<CollectionWorkPdf totalDelivery={totalDelivery} roll={formData.roll} />}>
                    {({ url }) => (
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <ClickButton label="View PDF" className="info-btn " />
                      </a>
                    )}
                  </BlobProvider>
                  {/* Excel Download Button */}
                  <span className="mx-2">
                    <ClickButton
                      onClick={downloadExcel}
                      label="Download Excel"
                      className="success-btn "
                    />
                  </span>

                </>
              ) : totalDelivery.collectionAgentSubmisson === false ? (
                <div>
                  {/* <p>No submission available at the moment.</p> */}
                  <h3>{totalDelivery.msg}</h3>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
      <div className="text-center daily-generation-section">
        <div className="fixed-button-bar">
          <div className="d-flex">
            <div>
              <p style={{ fontSize: "12px" }}>
                Copyright @ 2024 , VsAgency Developed By Barbikan
                Technologies.All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionAgent;
