import React, { useState, useEffect, useContext } from 'react';
import { Container, Col, Row, Spinner, Modal, Button } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { fetchAllUsers, createUser, updateUser, deleteUser } from './Api';
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/userContext';

const types = [
    {
        "label": "PEPSI",
        "value": "pepsi"
    },
    {
        "label": "BRITANIA",
        "value": "britannia"
    },
    {
        "label": "COLGATE",
        "value": "colgate"
    },
]
const User = () => {
    const { user } = useContext(UserContext)

    console.log('useruser', user)
    const navigate = useNavigate();
    const [userData, setUserData] = useState([]);
    console.log("userData", userData);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [formData, setFormData] = useState({
        user_name: '',
        role: '',
        login_id: '',
        phone_number: '',
        password: '',
    });
    console.log("formData", formData);

    const userRole = user?.role || '';
    console.log('User Role:', userRole);
    const fetchData = async () => {
        setLoading(true);
        try {
            const data = await fetchAllUsers();
            setUserData(data); // Set data directly
        } catch (error) {
            console.error("Error fetching users:", error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const phoneNumberRegex = /^\d{10}$/;
        if (!formData.user_name || !formData.role || !formData.phone_number || !formData.password) {
            Swal.fire({
                icon: 'warning',
                title: 'Missing fields',
                text: 'All fields are required.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
            return;
        }
        if (!phoneNumberRegex.test(formData.phone_number)) {
            Swal.fire({
                icon: 'warning',
                title: 'Invalid Phone Number',
                text: 'Phone number must be exactly 10 digits.',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
            return;
        }
        try {
            if (editMode) {
                await updateUser(selectedUserId, formData);
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'User details have been updated successfully!',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-ok-button-class',
                    },
                });
            } else {
                await createUser(formData);
                Swal.fire({
                    icon: 'success',
                    title: 'Created',
                    text: 'New user created successfully!',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'my-ok-button-class',
                    },
                });
            }
            setShowModal(false);
            setEditMode(false);
            setFormData({
                user_name: '',
                role: '',
                login_id: '',
                phone_number: '',
                password: '',
            });
            fetchData();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: `An error occurred: ${error.message}`,
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'my-ok-button-class',
                },
            });
        }
    };


    const handleOpenModal = (user = null) => {
        setShowModal(true);
        if (user) {
            setEditMode(true);
            setSelectedUserId(user.user_id); // Use user_id for update
            setFormData({
                user_name: user.user_name,
                role: user.role,
                login_id: user.login_id,
                phone_number: user.phone_number,
                password: user.password, // Clear password for update
            });
        } else {
            setEditMode(false);
            setFormData({
                user_name: '',
                role: '',
                login_id: '',
                phone_number: '',
                password: '',
            });
        }
    };

    const handleDeleteUser = (userId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'my-confirm-button-class',
                cancelButton: 'my-cancel-button-class',
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteUser(userId);
                    fetchData();
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'User Detail Deleted successfully.',
                        icon: 'success',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'my-ok-button-class',
                        },
                    })
                } catch (error) {
                    Swal.fire(
                        'Error!',
                        `An error occurred while deleting: ${error.message}`,
                        'error'
                    );
                }
            }
        });
    };


    const columns = [
        { field: 'user_name', headerName: 'User Name', width: 300 },
        { field: 'role', headerName: 'Role', width: 300 },
        { field: 'login_id', headerName: 'Login Id', width: 300 },
        { field: 'phone_number', headerName: 'Phone Number', width: 300 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 392,
            renderCell: (params) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <FaUserEdit className='userEdit icon-edit'
                        onClick={() => handleOpenModal(params.row)}

                    />
                    <MdDelete className='userEdit icon-edit'
                        onClick={() => handleDeleteUser(params.row.user_id)}

                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (!localStorage.getItem("userid")) {
            navigate("/login")
        }
    }, [])
    if (!user) {
        // You can handle this scenario by showing a loading spinner or redirecting to login page
        return <Spinner animation="border" />;
    }
    const getRoleOptions = () => {
        if (user.role === 'admin') {
            return [
                { value: "admin", label: "ADMIN" },
                { value: "pepsimanager", label: "PEPSI MANAGER" },
                { value: "britaniamanager", label: "BRITANIA MANAGER" },
                { value: "colgatemanager", label: "COLGATE MANAGER" },
                { value: "laysmanager", label: "LAYS MANAGER" },
                { value: "mis", label: "MIS" },
                { value: "collectionagent", label: "COLLECTION AGENT" },
                { value: "deliveryagent", label: "DELIVERY AGENT" },
                { value: "salesagent", label: "SALES AGENT" },
            ];
        } else if (user.role === 'pepsimanager'  ) {
            return [{ value: "deliveryagent", label: "DELIVERY AGENT" }];
        }else if (user.role === 'britaniamanager'  ) {
            return [{ value: "deliveryagent", label: "DELIVERY AGENT" }];
        }else if (user.role === 'colgatemanager'  ) {
            return [{ value: "deliveryagent", label: "DELIVERY AGENT" }];
        }else if (user.role === 'laysmanager'  ) {
            return [{ value: "deliveryagent", label: "DELIVERY AGENT" }];
        }
        return [];
    };

    const roleOptions = getRoleOptions();

    return (
        <Container fluid>
            <div className='main my-3 ' style={{ height: "89vh" }}>
                <div>
                    <Row>
                        <Col lg="6" md="6" xs="6">
                            <div className="page-nav py-3 h5">
                                <h3>Users</h3>
                            </div>
                        </Col>
                        <Col lg="6" md="6" xs="6">
                            <div className='text-end py-3'>
                                <Button variant="contained" onClick={() => handleOpenModal()} className='add-new'>
                                    Add New
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{ height: 500, width: '100%' }}>
                                <DataGrid
                                    rows={userData}
                                    columns={columns}
                                    loading={loading}
                                    autoPageSize
                                    getRowId={(row) => row.user_id}
                                    disableSelectionOnClick
                                    sx={{
                                        '& .MuiDataGrid-columnHeader': {
                                            backgroundColor: '#4B0082',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                '.MuiSvgIcon-root': {
                                                    color: 'white', // Change the icon color to white on hover
                                                },
                                            },
                                        },
                                        '& .MuiDataGrid-columnHeader--alignLeft': {
                                            textAlign: 'left',
                                        },
                                        '& .MuiDataGrid-columnHeader--sortable': {
                                            cursor: 'pointer',
                                        },
                                        '& .MuiDataGrid-withBorderColor': {
                                            borderColor: '#530004',
                                        },
                                        '& .MuiDataGrid-scrollbar--vertical': {
                                            display: 'none',
                                        },
                                    }}
                                />


                            </div>
                        </Col>
                    </Row>
                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>{editMode ? "Edit User" : "Add New User"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TextField
                                label="User Name"
                                name="user_name"
                                value={formData.user_name}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            {/* <FormControl fullWidth margin="normal">
                                <InputLabel>Role</InputLabel>
                                <Select
                                    label="Role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                >

                                    <MenuItem value="admin">ADMIN</MenuItem>
                                    <MenuItem value="pepsimanager">PEPSI MANAGER</MenuItem>
                                    <MenuItem value="britanniamanager">BRITANIA MANAGER</MenuItem>
                                    <MenuItem value="colgatemanager">COLGATE MANAGER</MenuItem>
                                    <MenuItem value="laysmanager">LAYS MANAGER</MenuItem>
                                    <MenuItem value="mis">MIS</MenuItem>
                                    <MenuItem value="collectionagent">COLLECTION AGENT</MenuItem>
                                    <MenuItem value="deliveryagent">DELIVERY AGENT</MenuItem>
                                    <MenuItem value="salesagent">SALES AGENT</MenuItem>
                                </Select>
                            </FormControl> */}
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="role-label">Role</InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role-select"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleInputChange}
                                    label="Role"
                                >
                                    {roleOptions.length > 0 ? (
                                        roleOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="" disabled>
                                            No options available
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <TextField
                                label="Login Id"
                                name="login_id"
                                value={formData.login_id}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Phone Number"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleInputChange}
                                fullWidth
                                margin="normal"
                                inputProps={{
                                    pattern: "[0-9]{10}", // Only digits, exactly 10 characters long
                                    maxLength: 10,         // Limit input to 10 characters
                                }}
                                helperText="Phone number must be 10 digits"
                                error={formData.phone_number && !/^\d{10}$/.test(formData.phone_number)}
                            />
                            <TextField
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                type="password"
                                fullWidth
                                margin="normal"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModal(false)} className='add-new'>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSubmit} className='add-new'>
                                {editMode ? "Update" : "Submit"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div >
            {/* <div className="daily-generation-section">
                <div className="fixed-button-bar">
                    <div className="d-flex">
                        <div>
                            kannan
                        </div>
                        <div className=''>
                            version
                        </div>
                    </div>
                </div>
            </div> */}
        </Container >
    );
};

export default User;
